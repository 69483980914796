import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Footer } from 'components/theme';
import { Global } from './styles';
import './fonts.css';

export const Layout = ({ children }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <script
        async
        defer
        data-website-id="fe22e172-91a6-4b5b-b26a-03d024726da0"
        src="https://umami.apollo.yzi.ch/umami.js"
      ></script>
      <Global theme={theme} />
      {children}
      <Footer />
    </>
  );
};
