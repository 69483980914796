module.exports = {
  defaultTitle: 'Yannik Zimmermann',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Yannik Zimmermann',
  url: 'https://portfolio.smakosh.com',
  legalName: 'Yannik Zimmermann',
  defaultDescription: 'I’m Yannik and I’m a Fullstack Web Developer & Devops engineer!',
  socialLinks: {
    twitter: 'http://www.twitter.com/Yannik_BZ',
    github: 'https://github.com/tschannik',
    linkedin: 'https://www.linkedin.com/in/ismail-ghallou-630149122/',
    instagram: 'https://instagram.com/tschann1k',
  },
  // googleAnalyticsID: 'UA-88875900-4',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    twitter: '@yannik_bz',
  },
  address: {
    city: 'Vitznau',
    region: 'Lucerne',
    country: 'Switzerland',
    zipCode: '6354',
  },
  contact: {
    email: 'yannik.z@me.com',
    phone: '+41 77 407 20 38',
  },
  foundingDate: '2015',
};
